#contacts {
    position: relative;
    background-color: $lightblue;
    padding: 0;

    .contact-block {
        width: 360px;
        padding: 160px 0;

        p {
            margin-bottom: 60px;

            &.title {
                margin-bottom: 20px;
            }
        }
        form {
            margin-bottom: 40px;

            input, textarea {
                width: 100%;
                padding: 17px 30px;
                margin-bottom: 20px;
                border: none;
            }

            textarea {
                height: 180px;

                &:focus {
                    outline: none;
                }
            }

            button {
                background-color: $blue;
                border: solid 1px $blue;
                color: $white;
                padding: 15px 32px;
                transition: $transition;

                &:hover {
                    background-color: $lightblue;
                    color: $blue;
                    transition: $transition;
                }
            }
        }

        a {
            position: relative;
            height: 36px;display: inline-block;
            color: $blue;

            &::before {
                content: "";
                position: absolute;
                width: 17px;
                height: 20px;
                left: 0px;
                background-color: $blueicon;
                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
            }

            &.phone {
                width: 100%;
                padding-left: 30px;

                &::before {
                    mask-image: url(/assets/template/images/icons/phone.svg);
                }
            }

            &.address {
                width: 100%;
                padding-left: 30px;

                &::before {
                    mask-image: url(/assets/template/images/icons/point.svg);
                }
            }

            &.email {
                width: 100%;
                padding-left: 30px;

                &::before {
                    mask-image: url(/assets/template/images/icons/envelope-solid.svg);
                }
            }

            &.vk {
                &::before {
                    mask-image: url(/assets/template/images/icons/vk.svg);
                    width: 20px;
                }
            }

            &.youtube {
                margin-top: 10px;
                margin-left: 30px;
                &::before {
                    mask-image: url(/assets/template/images/icons/youtube.svg);
                    width: 20px;
                }
            }
        }
    }



    .map {
        position: absolute;
        width: 50%;
        height: 100%;
        
        #map {
            filter: grayscale(100%);
        }
    }
}

