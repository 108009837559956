.blue {
    background-color: $blue;
    color: $white;
}

header {
    width: 100%;
    height: 147px;
    // position: relative;
    // background: rgb(255,255,255);
    // background: linear-gradient(90deg, rgba(255,255,255,1) 62%, rgba(56,78,203,1) 62%);

    position: fixed;
    top: 0;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 65%, rgba(56,78,203,1) 65%);
    z-index: 999;

    .header-search {
        display: flex;
        align-items: center;
        margin-top: 55px;
        height: 40px;
        position: relative;
        width: 180px;

        @media screen and (min-width: 1200px) and (max-width: 1660px) {
            margin-left: 25%;
        }

        input {
            width: 180px;

            &.search-submit {
                background: transparent;
                opacity: 0;
                color: transparent;
                border: none;
                outline: none;
                z-index: 1;
                width: 17px;
                height: 17px;
                display: block;
                position: absolute;
                right: -17px;
                top: 15px;
                padding: 0;
                margin: 0;
                line-height: 17px;
                text-align: center;
                cursor: pointer;
            }
        }

        .icon-search {
            position: relative;
            width: 17px;
            height: 17px;
            display: block;
            position: absolute;
            right: -17px;
            top: 15px;
            padding: 0;
            margin: 0;
            line-height: 17px;
            text-align: center;
            cursor: pointer;

            &::after {
                content: '';
                position: absolute;
                width: 17px;
                height: 17px;
                line-height: 17px;
                top: 0px;
                left: 0px;
                padding: 0;
                margin: 0;
                background-color: $blue;
                mask-image: url(/assets/template/images/icons/search.svg);
                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
                cursor: pointer;
            }
        }

    }

    .blue {
        width: 100%;
        height: 147px;
        padding: 0 60px 0 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .contact-block {
            @media screen and (min-width: 1200px) and (max-width: 1439px) {
                display: flex;
                flex-direction: column;
            }
            
            a {
                color: $white;
                margin-left: 30px;
                margin-right: 40px;
                position: relative;

                @media screen and (min-width: 1200px) and (max-width: 1439px) {
                    margin: 8px 0;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 17px;
                    height: 17px;
                    left: -30px;
                    background-color: $white;
                    mask-size: contain;
                    mask-position: center;
                    mask-repeat: no-repeat;
                }

                &.phone {

                    &::before {
                        mask-image: url(/assets/template/images/icons/phone.svg);
                    }
                }

                &.email {

                    &::before {
                        mask-image: url(/assets/template/images/icons/envelope-solid.svg);
                    }
                }

                &.address {

                    &::before {
                        mask-image: url(/assets/template/images/icons/point.svg);
                    }
                }

                &:hover {
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }

    .logo-block {
        position: absolute;
        top: 50px;
        right: 70px;
        z-index: 1;
    }
}