.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;  
    background: #fff;
    transition: all 0.5s;
    opacity: 1;

    &.preloader-remove {
        opacity: 0;
        z-index: -10;
    }

    .preloader-spiner {
        display: block;
        position: relative;
        width: 150px;
        height: 150px;
        margin: 30px auto;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: $lightblue;
        animation: preloader-5-spin 2s linear infinite;
    
        &::before {
            content: "";
            position: absolute;
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: $blue;
            animation: preloader-5-spin 3s linear infinite;
        }
    
        &::after {
            content: "";
            position: absolute;
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-radius: 50%;
            border: 3px solid transparent;
            border-top-color: $lightblue;
            animation: preloader-5-spin 1.5s linear infinite;
        }
    }
    
    @keyframes preloader-5-spin {
        0%   {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}


.preloader-5 {
    display: block;
    position: relative;
    width: 150px;
    height: 150px;
    margin: 30px auto;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $blue;
    animation: preloader-5-spin 2s linear infinite;

    &::before {
        content: "";
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: $lightblue;
        animation: preloader-5-spin 3s linear infinite;
    }

    &::after {
        content: "";
        position: absolute;
        top: 15px;
        left: 15px;
        right: 15px;
        bottom: 15px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: $blue;
        animation: preloader-5-spin 1.5s linear infinite;
    }
}

@keyframes preloader-5-spin {
    0%   {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}