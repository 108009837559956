footer {
    padding-top: 160px;
    padding-bottom: 120px;
    
    p {
        &.footer-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: $grey;
            margin-bottom: 60px;
        }
    }
    
    ul {
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 10px;
            a {
                font-weight: 400;
                font-size: 15px;
                line-height: 18px;
                color: $grey;
            }
        }
    }

    .copyright-block {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;

        .logo {

        }

        .copyright{

            a {
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
            }
        }
    }

    .container {
        position: relative;

        .col-md-4 {
            @media screen and (min-width: 480px) and (max-width: 1200px) {
                margin-bottom: 50px;
            }
        }

        .footer_bearing {
            position: absolute;
            background-image: url(../images/tmp/footer_bearing.png);
            width: 388px;
            height: 259px;
            left: 0;
            bottom: -120px;
        }
    }
}