#gallery {
    padding: 160px 0 0 0;
    a {

        &:hover {
            .album {
                .album-title {
                    background-color: $lightblue;
                    color: $blue;
                    transition: all .3s;

                    &::after {
                        animation: swing 0.6s ease;
                        background-color: $blue;
                    }
                }
            }
        }

        &.goback {
            color: $blue;
            text-decoration: none;
            position: absolute;
            font-size: 18px;
            top: 17px;
            padding-left: 30px;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 24px;
                margin-top: -9px;
                height: 17px;
                mask-image: url(/assets/template/images/icons/right_arrow.svg);
                transform: rotate(180deg);
                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
                background-color: $blue;
            }
        }

        .album {
            height: 340px;
            margin-bottom: 30px;
            position: relative;
            background-size: cover;
            transition: .3s;

            .album-title {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: $blue;
                color: white;
                padding: 30px 70px 30px 30px;
                font-weight: 700;
                font-size: 22px;
                line-height: 27px;
                transition: all .3s;

                @media screen and (max-width: 1400px) {
                    font-size: 18px;
                    font-weight: 600;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 35px;
                    width: 24px;
                    margin-top: -9px;
                    height: 17px;
                    mask-image: url(/assets/template/images/icons/right_arrow.svg);
                    mask-size: contain;
                    mask-position: center;
                    mask-repeat: no-repeat;
                    background-color: #fff;

                }
            }
        }
    }


    a {
        .item-album-image {
            margin-bottom: 30px;
            height: 340px;
            overflow: hidden;

            position: relative;
            z-index: 1;
            display: inline-block;
            overflow: hidden;
            text-align: center;
            background: $lightblue;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                opacity: 1;
                transition: opacity 0.5s;
                position: relative;
                max-width: 100%;
                display: block;               
            }

            div {

                img, i {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -24px;
                    margin-left: -24px;
                    width: 48px;
                    height: 48px;
                    transition: transform 0.5s ease 0s;
                    transform: scale(0);
                    font-size: 48px;
                    color: $lightblue;
                }

                &::before {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    bottom: 20px;
                    left: 20px;
                    content: "";
                    opacity: 0;
                    transition: opacity 0.5s, transform 0.5s;
                    pointer-events: none;

                    border-top: 2px solid $lightblue;
                    border-bottom: 2px solid $lightblue;
                    transform: scale(0, 1);
                }

                &::after {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    bottom: 20px;
                    left: 20px;
                    content: "";
                    opacity: 0;
                    transition: opacity 0.5s, transform 0.5s;
                    pointer-events: none;

                    border-right: 2px solid $lightblue;
                    border-left: 2px solid $lightblue;
                    transform: scale(1, 0);
                }
            }
        }

        &:hover {

            .item-album-image {
                img {
                    opacity: 0.5;
                }

                div {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    img, i {
                        transform: scale(1);
                        opacity: 1;
                    }

                    &::before {
                        opacity: 1;
                        transform: scale(1);
                    }

                    &::after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }
    }
}

.modal-content {
    border-radius: 0 !important;
}

.ekko-lightbox-nav-overlay {
    a {
        span {
            // width: 50px !important;
            // height: 50px !important;
            padding: 30px !important;
            background-color: white !important;
        }

        &:first-of-type {
            justify-content: left;
        }

        &:last-of-type {
            justify-content: right;
        }

        &:hover {
            color: $blue !important;
        }
    }
}

.ekko-lightbox-nav-overlay a>* {
    flex-grow: 0 !important;
}

@keyframes swing {
    15% {
      transform: translateX(9px);
    }
    30% {
      transform: translateX(-9px);
    }
    40% {
      transform: translateX(6px);
    }
    50% {
      transform: translateX(-6px);
    }
    65% {
      transform: translateX(3px);
    }
    100% {
      transform: translateX(0);
    }
  }