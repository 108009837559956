$blueicon: #4365AD;
$blue: #384ECB;
$lightblue: #F5F7FF;
$grey: #5B5F74;
$white: #FFFFFF;

$transition: all .3s;

body {
    overflow-x: hidden;
    // box-sizing:border-box;
    overflow-anchor: none;
    position: relative;
}

html, body, h1, h2, h3, h4, h5, h6, p, a {
    font-family: 'Montserrat';
}

a {
    color: $blue;
    text-decoration: none;

    &:hover {
        color: $blue;
    }
}

a, p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

ul {
    li {
        list-style-type: none;
    }
}

.lb {
    background-color: $lightblue;
}

p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: $grey;
    margin-bottom: 40px;

    &.title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        // text-transform: uppercase;
        color: $blue;
        margin-bottom: 20px;
    }
}

section {
    padding: 160px 0;

    &:first-of-type {
        margin-top: 147px;
    }

    p.title {
        margin-bottom: 70px;
		
        .breakword {
            word-break: break-word;
        }
    }
}

.pr-130 {
    padding-right: 130px;
}

@import "./fonts.scss";
@import "./inputs.scss";
@import "./media.scss";
@import "./preloader.scss";
@import "./mobile.scss";
@import "./scroll.scss";

@import "./header.scss";
@import "./menuBtn.scss";
@import "./slick_slider.scss";

@import "./partners.scss";
@import "./certificates";

@import "./about.scss";
@import "./service.scss";
@import "./application.scss";
@import "./contacts.scss";
@import "./search.scss";
@import "./mission.scss";
@import "./history.scss";
@import "./production_base";
@import "./tech_base.scss";
@import "./404.scss";
@import "./content.scss";
@import "./table.scss";
@import "./auth.scss";
@import "./footer.scss";

@import "./documents.scss";
@import "./gallery.scss";
@import "./news.scss";

@import "./upBtn.scss";
@import "./callback.scss";
@import "./back_btn.scss";

@import "./social.scss";
@import "./telegramm_btn.scss";