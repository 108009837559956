#application {

    .row {

        &:nth-child(2) {
            margin-bottom: 30px;
        }

        .block-lightblue {
            position: relative;
            background-color: $lightblue;
            padding: 50px;
            height: 250px;
            margin-bottom: 30px;
    
            p.title {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: $grey;

                @media screen and (min-width: 1400px) and (max-width: 1439px) {
                    font-size: 20px;
                }
            }

            a {
                position: absolute;
                width: 100px;
                height: 60px;
                padding: 22px 38px;
                background-color: $blue;
                border: solid 1px $blue;
                bottom: 50px;
                transition: $transition;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -6px;
                    margin-left: -9px;
                    width: 18px;
                    height: 12px;
                    mask-image: url(/assets/template/images/icons/right_arrow.svg);
                    mask-size: contain;
                    mask-position: center;
                    mask-repeat: no-repeat;
                    background-color: $white;
                    transition: $transition;
                }

                &:hover {
                    background-color: $white;
                    transition: $transition;

                    &::after {
                        background-color: $blue;
                        transition: $transition;
                    }
                }
            }
        }
    }
}