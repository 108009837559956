header .blue {
    justify-content: left !important;    
}

header .social a {
    color: #fff;
}

header a.telegram {
    margin-left: 30px;
}

header a.telegram svg {
    fill: #fff;
}

footer a.telegram svg {
    fill: #384ecb;
}