menu {
    margin: 0;
    padding: 0;  
    position: relative;

    & .overlay {
        position: fixed;
        // width: 100%;
        height: 100vh;
        z-index: -1;
        // transition: all .7s ease;
        transition: all .3s;

        &.visible {
            background-color: rgba(255, 255, 255, .1);
            backdrop-filter: blur(2px);
            z-index: 9;
            width: 100%;
            // transition: all .7s ease;
            transition: all .3s;
        }
    }

    & .menuBlock {
        width: 301px;
        position: fixed;
        left: -301px;
        background-color: $blue;
        height: 100vh;
        z-index: 10;
        // transition: all .7s ease;
        transition: all .3s;

        @media screen and (max-width: 480px) {
            width: 100%;
            left: -100%;
        }

        &.open {
            left: 0;
            // transition: all .7s ease;
            transition: all .3s;
            // box-shadow: 0 6px 14px rgba(11, 31, 53, .15);
        }

        & ul {
            margin-top: 147px;
            padding: 0;

            @media screen and (max-height: 600px) {
                // меню - вытягиваем меню вверх
                margin-top: 80px;
            }

            & li {
                list-style-type: none;
                width: 100%;
                height: auto;
                
                & a {
                    display:block;
                    height:100%;
                    padding: 23px 70px;
                    text-decoration: none;
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 18px;
                    // line-height: 50px;
                    transition: all .3s;
                    color: $white;

                    &:hover {
                        background-color: $white;
                        color: $blue;
                        transition: all .3s;
                    }

                    &.inner-open-link {
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 5px;
                            height: 5px;
                            line-height: 5px;
                            top: 30px;
                            right: 100px;
                            padding: 0;
                            margin: 0;
                            background-color: $white;
                            mask-image: url(/assets/template/images/icons/menu_arrow.svg);
                            mask-size: contain;
                            mask-position: center;
                            mask-repeat: no-repeat;
                        }

                        &:hover {
                            &::after {
                                background-color: $blue;
                            }
                        }
                    }
                }

                .menuWhiteBlock {
                    position: fixed;
                    left: 301px;
                    width: 0;
                    height: 100vh;
                    background-color: $white;
                    opacity: 0;
                    z-index: -1;
                    // transition: all .7s;
                    transition: all .3s;

                    ul {
                        padding: 0;
                        margin-top: -40px;
                        display: none;
                    }
                }


                &.open {

                    a {
                        background-color: $white;
                        color: $blue;

                        &.inner-open-link {
    
                            &::after {
                                background-color: $blue;
                            }
                        }
                    }

                    .menuWhiteBlock {
                        left: 301px;
                        width: auto;
                        transition: $transition;
                        opacity: 1;
                        // box-shadow: 0 6px 14px rgba(11, 31, 53, .15);

                        @media screen and (max-height: 600px) {
                            //меню - высота раскрывающегося блока при маленькой высоте экрана
                            top: 120px;
                        }
                        
                        
                        &::before {
                            content: '';
                            background-color: $white;
                            width: 100%;
                            height: 150vh;
                            position: absolute;
                            top: -600px;
                            z-index: -1;
                            // box-shadow: 0 6px 14px rgba(11, 31, 53, .15);
                            transition: $transition;
                        }

                        ul {
                            display: block; 
                            transition: all 0;
                            
                            li {

                                &:first-of-type {
                                    margin-top: 50px;
                                }

                                a {
                                    color: $blue;
                                    white-space: nowrap;
                                    font-weight: 500;
                                    font-size: 15px;

                                    &:hover {
                                        background-color: $blue;
                                        color: $white;
                                    }
                                }
                            }
                            
    
                            span {
                                color: $blue;
                                font-weight: 700;
                                font-size: 15px;
                                line-height: 18px;
                                margin-bottom: 50px;
                                padding: 0 70px;

                                @media screen and (max-width: 420px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    & .menuBtn {
        position: absolute;
        top: 67px;
        left: 70px;
        z-index: 10;
        transition: all .7s ease;

        @media screen and (max-width: 480px) {
            top: 5%;
            left: 5%;
        }

        & .con {
            cursor: pointer;
            display: inline-block;
            width: auto;
            margin: 0 auto;
            // transition: all .7s ease;
            transition: all .3s;

            & .bar {
                display: block;
                height: 2px;
                width: 25px;
                background: $blue;
                margin: 5px auto;
                // transition: all .7s ease;
                transition: all .3s;

                & .middle {
                    margin: 0 auto;
                }
            }
        }

        &.open {

            &:hover {
                & .bar {
                    background: $white;
                }
            }

            & .bar {
                background: $white;

                &.top {
                    transform: translateY(7px) rotateZ(45deg);
                }
                    
                &.bottom {
                    transform: translateY(-7px) rotateZ(-45deg);
                }
                    
                &.middle {
                    width: 0;
                }
            }
        }
    }
}