#search {
    padding-bottom: 40px;
    p {
        &.title {
            margin-bottom: 56px;
        }
    }

    .noResult {
        margin-bottom: 120px;
    }

    .item-search {
        margin-bottom: 120px;

        .item-title {
            font-weight: 700;
            font-size: 24px;
            color: $grey;
            margin-bottom: 20px;
        }

        .item-content {
            font-weight: 400;
            font-size: 18px;
            line-height: 33px;
            color: $grey;
            margin-bottom: 110px;
        }

        a {
            position: relative;
            padding: 22px 60px 22px 38px;
            background-color: $blue;
            border: solid 1px $blue;
            color: $white;
            bottom: 50px;
            transition: $transition;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 25px;
                margin-top: -4px;
                width: 18px;
                height: 12px;
                mask-image: url(/assets/template/images/icons/right_arrow.svg);
                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
                background-color: $white;
                transition: $transition;
            }

            &:hover {
                background-color: $white;
                transition: $transition;
                color: $blue;

                &::after {
                    background-color: $blue;
                    transition: $transition;
                }
            }
        }
    }
}