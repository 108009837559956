.table {
    &.table-striped-lb {

        tbody {
            tr:nth-child(odd) {
                background-color: $lightblue;
            }
        }
    }

    .tblue {

        background-color: $blue;
        color: $white;
    }
}