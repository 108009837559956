.callback-btn {
    width: 60px;
    height: 60px;
    background-color: $blue;
    // border: solid 1px $blue;
    color: white;
    position: fixed;
    top: 50%;
    margin-top: -25px;
    right: 0;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    z-index: 3;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -13px;
        margin-left: -13px;
        width: 26px;
        height: 26px;
        mask-image: url(/assets/template/images/icons/envelope-solid.svg);
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;
        background-color: #fff;
        transition: all .3s;
    }

    &:hover {
        background-color: $lightblue;
        color: $blue;
        transition: all .3s;

        &::after {
            background-color: $blue;
            mask-image: url(/assets/template/images/icons/envelope-open-text-solid.svg);
        }
    }
}



.callback-modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;

    &.open {
        display: block;
    }

    .callback-overlay {
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.6);
    }



    .callback-form {
        width: 690px;
        min-height: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -369px;
        margin-left: -345px;
        padding: 100px 120px 60px;
        background-color: $lightblue;

        p {
            text-align: center;
            font-size: 17px;

            &.title {
                font-size: 36px;
                margin-bottom: 0;
            }

            &:last-of-type {
                margin-bottom: 40px;
            }
        }

        input {
            width: 100%;
            margin-bottom: 20px;
            padding: 17px 30px;
            color: $blue;
            background-color: white;
            transition: background-color .3s;
            border: none;

            &:focus {
                background-color: white;
                transition: background-color .3s;
            }
        }

        input[type="submit"] {
            background-color: $blue;
            color: $lightblue;
            border: 1px solid $blue;
            margin: 30px 0 0 0;
            transition: all .3s;

            &:hover {
                background-color: $lightblue;
                color: $blue;
                transition: all .3s;
            }
        }

        textarea {
            width: 100%;
            padding: 17px 30px;
            background-color: white;
            border: none;
            transition: background-color .3s;
            resize: none;

            &:focus {
                outline: none;
                color: $blue;
                background-color: white;
                transition: background-color .3s;
            }
        }

        .callback-close-btn {
            width: 50px;
            height: 50px;
            // background-color: $blue;
            position: absolute;
            top: 80px;
            right: 40px;

            cursor: pointer;
            z-index: 1;
    
            &::before {content: ""; position: absolute; width: 25px; height: 2px; background: $blue;} 
            &::after {content: ""; position: absolute; width: 25px; height: 2px; background: $blue;}
            &::before {transform: rotate(45deg);}
            &::after {transform: rotate(-45deg);}
        }
    }
}