#about {

    p.title {
        margin-bottom: 70px;
    }

    .ab {
        position: relative;

        &::before {
            position: absolute;
            content: '';
            background-image: url(/assets/template/images/tmp/about_bearing.png);
            width: 742px;
            height: 208px;
            bottom: -160px;
            right:250px;
        }
    }
}