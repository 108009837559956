#production_base {
    padding: 0;

    .lb {
        background-color: $lightblue;
        padding: 160px 160px 120px 125px;

        ul {
            margin: 0;
            padding: 0;

            li {
                position: relative;
                font-weight: 700;
                font-size: 18px;
                // line-height: 33px;
                margin-bottom: 33px;
                padding-left: 15px;
                color: $grey;

                &::before {
                    content: '';
                    position: absolute;
                    top: 10px;
                    left: -5px;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: $grey;
                }
            }
        }
    }

    .wb {
        padding: 160px 0 120px 140px;

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 33px;

            &.title {
                font-weight: 700;
                font-size: 36px;
            }
        }
    }

    .pb-image {
        position: relative;
        width: 100%;
        height: auto;

        .item-overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: rgba(14,26,94,0.35);        
        }

        .item-image {

            img {
                width: 100%;
            }
        }
    }
}