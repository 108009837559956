#history {
    @media screen and (min-width: 992px) {
        position: relative;
        padding-top: 160px;

        &::before {
            content: '';
            position: absolute;
            background-image: url(/assets/template/images/tmp/history_bearing.png);
            background-repeat: no-repeat;
            width: 364px;
            height: 100%;
        }
    }


    p {

        &.title {
            text-align: center;
            margin-bottom: 76px;
        }
    }

/* Timeline */
.timeline {
	border-left: 5px solid $blue;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
	position: relative;
    margin: 0 30px 0 170px;
	padding: 0 0 0 100px;
	list-style: none;
	text-align: left;
    background-color: $white;

    .event {
        border-bottom: 1px dashed rgba(255, 255, 255, 0.1);
        // padding-bottom: 25px;
        margin-bottom: 50px;
        position: relative;

        &:last-of-type {
            padding-bottom: 50px;
            margin-bottom: 0;
            border: none;

            p {
                margin-bottom: 0px;
            }
        }

        &::before, &::after {
            position: absolute;
            display: block;
            top: 0;
        }

        &::before {
            top: -5px;
            left: -295px;
            color: $grey;
            content: attr(data-date);
            text-align: right;
            min-width: 120px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 182.4%;
        }

        &::after {
            left: -112px;
            background: $blue;
            height: 20px;
            width: 20px;
            content: "";
            top: 0px;
        }

        p {
            position: relative;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 26px;
            color: $grey;
            margin-bottom: 30px;
            
            &::before {
                content: '';
                position: absolute;
                top: 12px;
                left: -13px;
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background-color: $grey;
            }
        }
    }
}




.timeline .event

.timeline .event:after {
	box-shadow: 0 0 0 4px #004ffc;
	left: -57.85px;
	background: #313534;
	border-radius: 50%;
	height: 11px;
	width: 11px;
	content: "";
	top: 5px;
}



}