@media screen and (max-width: 419px) {
    section {
        padding: 40px 0 !important;

        p {
            font-size: 14px !important;
            line-height: 21px !important;
            margin-bottom: 20px !important;
    
            &.title {
                font-size: 24px !important;
                line-height: 29px !important;
            }
        }

        ul {

            li {
                font-size: 14px !important;
                line-height: 30px !important;
            }
        }

        .blue-block {
            padding: 30px !important;
        }
    }


    header {
        display: flex;
        justify-content: space-between;
        // padding: 20px 15px;
        height: 65px !important;

        menu {

            .menuBtn {
                top: 20px;
                left: 15px;
            }

            .menuBlock {
                width: 100% !important;
                height: 100vh !important;

                .header-search {
                    position: absolute;
                    top: 10px;
                    right: 30px;
                    margin: 0;
                    width: 140px;

                    input {
                        width: 140px;
                        color: $white !important;
                        border-bottom: 1px solid $white;
                        background-color: $blue;
                    }

                    .icon-search {
                        &::after {
                            background-color: $white;
                        }
                    }
                }

                &.open {
                    
                    .contact-block {
                        position: absolute;
                        bottom: 70px;
                        left: 50px;
                        display: flex;
                        flex-direction: column;

                        a {
                            color: $white;
                            font-size: 13px;
                            line-height: 16px;
                            margin-bottom: 20px !important;

                            &::before {
                                content: "";
                                position: absolute;
                                width: 13px;
                                height: 13px;
                                left: -30px;
                                background-color: $white;
                                mask-size: contain;
                                mask-position: center;
                                mask-repeat: no-repeat;
                            }
            
                            &.phone {
            
                                &::before {
                                    mask-image: url(/assets/template/images/icons/phone.svg);
                                }
                            }
            
                            &.email {
            
                                &::before {
                                    mask-image: url(/assets/template/images/icons/envelope-solid.svg);
                                }
                            }

                            &.address {
            
                                &::before {
                                    mask-image: url(/assets/template/images/icons/point.svg);
                                }
                            }
                        }
                    }
                }

                ul {
                    margin-top: 70px;

                    li {

                        a {
                            padding: 15px;
                        }

                        .menuWhiteBlock {
                            position: relative;
                            width: 100% !important;
                            height: auto !important;
                            display: block !important;
                            left: 0;
                            z-index: 11;
                            
                            ul {
                                padding-left: 40px;
                                margin-top: -10px !important;
                                

                                li {

                                    &:first-of-type {
                                        margin: 0 !important;
                                    }
                                }
                            }
                        }

                        &.open {
                            .menuWhiteBlock {
                                left: 0;

                                &::before {
                                    width: 0;
                                }
                            }
                        }
                    }
                }

            }
        }

        .header-logo {
            padding-top: 20px;
            padding-right: 15px;
        }
    }


    #content {
        .block {
            padding: 30px 0 !important;

            &.blue {
                padding: 10px !important;
            }

            &.lightblue {
                padding: 10px !important;
            }
        }
    }


    #history {
        .timeline {
            margin-left: 110px !important;
            padding-left: 50px !important;

            .event {

                &::before {
                    font-size: 14px !important;
                    left: -160px !important;
                    min-width: 70px !important;
                }

                &::after {
                    left: -62px !important;
                }
            }
        }
    }


    #slider {
        padding: 0 !important;
        .slick-slider {

            .slick-dots {
                margin-top: 20px !important;
                margin-bottom: 0 !important; 
                justify-content: center;
            }

            .slick-arrow {
                width: 30px !important;
                height: 30px !important;
                bottom: 25% !important;
                
                &.slick-next {
                    left: 95% !important;
                }

                &.slick-prev {
                    left: 5% !important;
                    display: block !important;
                    margin-left: 0;
                    transform: rotate(180deg);
                }

                &::after {
                    margin-top: -4px !important;
                    margin-left: -7px !important;
                    width: 12px !important;
                    height: 9px !important;
                }
            }

            .pr-130 {
                padding-right: 0 !important;
            }

            .slider__item {

                .slider-content {
                    padding: 0 !important;
                }

                .slider-image {
                    width: calc(100% + 30px) !important;
                    margin-left: -15px;
                    height: 400px !important;
                    position: relative !important;
                }
            }
        }
    }


    #about {
        .container {
            margin-bottom: 20px !important;
        }
        .pr-130 {
            padding: 15px !important;
        }

        .ab {
            &::before {
                width: 350px !important;
                height: 100px !important;
                bottom: -40px !important;
                left: 0 !important;
                right: 0 !important;
                background-size: cover !important;
            }
        }
    }


    #service {
        .row {
            .blue-block {
                height: auto !important;
                margin-bottom: 15px !important;

                a {
                    display: inline-block !important;
                    position: relative !important;
                    bottom: 0 !important;
                }
            }
        }
    }

    #application {
        padding-bottom: 0 !important;

        .block-lightblue {
            height: 150px !important;
            padding: 30px !important;

            p.title {
                font-size: 16px !important;
                line-height: 20px !important;
            }

            a {
                width: 80px !important;
                height: 50px !important;
                bottom: auto !important;
            }
        }
    }


    #mission {
        padding-top: 0 !important;

        .mission-img {
            .item-overlay {
                width: 100% !important;
            }
            .item-img {
                width: 100% !important;
            }
        }
        .mission-content {
            padding: 30px !important;
        }
    }


    #production_base {

        .wb, .lb {
            padding: 30px !important;
        }

        .pb-image {
            width: calc(100% + 24px) !important;
            margin-left: -12px;
            // margin-right: 15px;
        }
    }


    #tech_base {

        .blue-block {
            margin-bottom: 15px !important;
            height: auto !important;

            p.title {
                font-size: 16px !important;
                line-height: 20px !important;
            }
        }
    }


    #contacts {

        padding: 0 !important;

        .contact-block {
            padding: 40px 0 !important;

            p {
                margin-bottom: 20px !important;
            }
        }

        .map {
            width: calc(100% + 24px) !important;
            height: 350px !important;
            position: relative !important;
            margin-left: -12px;
            // margin-right: 12px
        }
    }


    footer {
        padding: 0 !important;

        p {
            font-size: 14px !important;
            line-height: 17px !important;

            &.footer-title {
                font-size: 16px !important;
                line-height: 20px !important;
                margin: 0 !important;
                padding: 40px 0 20px !important;
            }
        }

        .copyright-block {
            flex-direction: column;

            .logo {
                text-align: right;
            }

            .copyright {
                text-align: right;
                
                a {
                    font-weight: 500 !important;
                    font-size: 15px !important;
                    line-height: 18px !important;
                    color: $blue !important;
                }
            }
        }

        .footer_bearing {
            width: 187px !important;
            height: 124px !important;
            background-size: cover !important;
            bottom: -40px !important;
            left: -30px !important;
        }
    }
}

@media (min-width: 420px) and (max-width: 991px) {
    section {
        padding: 100px 0 !important;
    }

    p {
        font-size: 16px !important;
        line-height: 29px !important;
        margin-bottom: 20px !important;

        &.title {
            font-size: 24px !important;
            line-height: 29px !important;
            margin-bottom: 20px !important;
        }
    }

    ul {

        li {
            font-size: 16px !important;
            margin-bottom: 29px !important;
            padding-left: 15px !important;
            line-height: 29px !important;
        }
    }
    
    .container {
        width: 100%;
        max-width: 991px;
        padding-right: 30px;
        padding-left: 30px;
    }


    header {
        height: 95px !important;
        background: rgb(255,255,255);
        background: linear-gradient(90deg, rgba(255,255,255,1) 64%, rgba(56,78,203,1) 64%);

        .logo-block {
            top: 30px !important;
            right: 30px !important;
        }

        menu {
            .menuBtn {
                top: 30px;
                left: 30px;
            }

            .menuBlock {
                width: 190px;
                left: -190px;

                ul {

                    li {
                        margin: 0 !important;
                        padding: 0 !important;
                        
                        a {
                            font-size: 14px !important;
                            line-height: 17px !important;
                            padding: 12px 54px 12px 31px;
                            position: relative;

                            &.inner-open-link {

                                &::after {
                                    top: 18px;
                                    right: 35px;
                                }
                            }
                        }

                        .menuWhiteBlock {
                            left: 190px !important;

                            ul {
                                padding-top: 7px;
                                span {
                                    padding: 20px !important;
                                }

                                li {

                                    a {
                                        padding-left: 20px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }

        .header-search {
            margin-top: 20px !important;
            margin-left: 70px;
            width: 144px !important;

            input {
                width: 144px !important;

				&.search-submit {
					width: 20px !important;
				}
            }
        }
        
        .blue {
            padding: 0 0 0 24px !important;
            height: 95px !important;

            .contact-block {
                display: flex;
				flex-direction: column;

                a {
                    font-size: 13px !important;
                    line-height: 16px !important;
					margin: 8px 0;

                    &::before {
                        // left: -12px !important;
                    }

                    &.phone {
                        margin-right: 20px;
                        // margin-left: 24px;
                    }
                }
            }
        }
    }


    #slider {
        padding: 0 !important;
        .pr-130 {
            padding-right: 65px;
        }

        .slider-content {
            padding: 90px 0 !important;

            // p {

            //     &.title {
            //         font-size: 24px;
            //         line-height: 29px;
            //         margin-bottom: 20px;
            //     }
            // }
        }

        .slider-image {
            width: calc(66.66666667% - 18px) !important;
        }

        .slick-arrow {
            
            &.slick-next {
                left: 33.3333% !important;
                width: 60px !important;
                height: 60px !important;
                margin-left: -10px !important;
            }
        }

        .slick-dots {
            margin-top: -50px !important;
            margin-bottom: 27px !important;
        }
    }


    #about {

        .pr-130 {
            padding-right: 56px !important;
        }

        .ab {

            &::before {
                width: 380px !important;
                height: 106px !important;
                right: 88px !important;
                bottom: -100px !important;
                background-size: cover;
            }
        }
    }


    #service {
        padding-bottom: 0 !important;

        .blue-block {
            padding: 30px !important;
            
            &.fr {
                height: 550px !important;
            }

            &.sr {
                height: 350px !important;
            }

            &.sb {
                &::before {
                    left: -210px !important;
                    top: -480px !important;
                    z-index: -1 !important;
                }
            }

            p {
                font-size: 14px !important;
                line-height: 21px !important;

                &.title {
                    font-size: 16px !important;
                    line-height: 20px !important;
                }
            }

            a {
                font-size: 13px !important;
                line-height: 16px !important;
                padding: 17px 50px 17px 20px !important;

                &::after {
                    right: 21px !important;
                }
            }
        }
    }


    #application {
        .block-lightblue {
            padding: 30px !important;
            height: 190px !important;

            p {

                &.title {
                    font-size: 16px !important;
                    line-height: 20px !important;
                }
            }

            a {
                width: 80px !important;
                height: 50px !important;
            }
        }
    }


    #mission {
        padding: 0 !important;
        

        .mission-img {
            height: 100%;

            .item-overlay {
                width: calc(33.33333333% + 18px) !important;

            }

            .item-img {
                width: calc(33.33333333% + 18px) !important;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: auto !important;
                    height: 100%;
                }
            }
        }

        .mission-content {
            padding: 100px 0 100px 30px !important;
        }
    }


    #production_base {
        padding: 0 !important;

        .lb {
            padding: 100px 30px!important;
        }
        
        .wb {
            padding: 100px 30px !important;
        }

        .col-md-4 {
            // width: calc(33.33333333% + 12px);
            margin-left: 12px;
        }

        .col-md-8 {
            width: calc(66.66666667% - 12px);
        }
    }


    #tech_base {
        .blue-block {
            padding: 30px !important;
            // margin-bottom: 20px !important;
            height: calc(100% - 30px) !important;
        }
    }


    #content {
        padding: 0 !important;

        .block {
            padding: 100px 30px !important;
        }
    }


    #contacts {
        padding: 0 !important;
        .contact-block {
            width: 100% !important;
            padding-top: 100px !important;
            padding-bottom: 100px !important;
            padding-right: 10px !important;
        }

        .map {
            width: calc(66.66666667% - 18px) !important;
        }
    }


    .footer {
        padding: 100px 0 70px !important;

        p {

            &.footer-title {
                font-size: 16px !important;
                line-height: 20px !important;
                margin-bottom: 20px !important;
            }
        }

        a {
            font-size: 14px !important;
            line-height: 17px !important;
        }

        .footer_bearing {
            width: 303px !important;
            height: 200px !important;
            left: 10px !important;
            background-size: cover !important;
            bottom: -70px !important;
        }
    }
}

@media (min-width: 1440px) {
    header {
        .contact-block {
            max-width: 450px;
        }
    }
}