.up {
    z-index: 3;
    width: 100px;
    height: 100px;
    background-color: $blue;
    color: $white;
    position: fixed;
    bottom: 100px;
    right: 100px;
    display: none;
    cursor: pointer;
    transition: background-color .3s;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -8px;
        margin-left: -12px;
        width: 24px;
        height: 17px;
        mask-image: url(/assets/template/images/icons/right_arrow.svg);
        mask-size: contain;
        mask-position: center;
        mask-repeat: no-repeat;
        background-color: $white;
        transform: rotate(-90deg);
    }

    &.show {
        display: block;
    } 

    &:hover {
        background-color: $lightblue;
        color: $blue;
        transition: background-color .3s;

        &::after {
            background-color: $blue;
        }
    }
}

@media (min-width: 768px) and (max-width: 1400px) {
    .up {
        width: 60px;
        height: 60px;
        bottom: 60px;
        right: 60px;
    }
}