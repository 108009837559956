@media (min-width: 1440px) {
    .container {
        max-width: 1440px;
    }
}

@media (max-width: 480px){
    #partners {
        margin-bottom: 0px !important;
    }
    #partners .partners .slick-dots {
        margin-top: 30px !important;
    }
}