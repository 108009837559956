#docs {
    .doc {
        width: 100%;
        background-color: $blue;
        color: $white;
        padding: 35px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            color: $white;
            font-size: 18px;

            &.doc-name {
                position: relative;
                margin-left: 34px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-top: 0;
                    margin-left: -34px;
                    width: 24px;
                    height: 24px;
                    mask-image: url(/assets/template/images/icons/doc.svg);
                    mask-size: contain;
                    mask-position: center;
                    mask-repeat: no-repeat;
                    background-color: #fff;
                }
            }

            &.doc-download {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-top: -10px;
                    width: 24px;
                    height: 24px;
                    mask-image: url(/assets/template/images/icons/download.svg);
                    mask-size: contain;
                    mask-position: center;
                    mask-repeat: no-repeat;
                    background-color: #fff;
                }
            }
        }
    }
}