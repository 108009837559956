
    .telegram_channel {
        width: 370px;
        height: 60px;
        background-color: #384ECB;
        color: white;
        position: fixed;
        top: 50%;
        margin-top: -90px;
        right: -310px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transition: all .3s;
        -o-transition: all .3s;
        transition: all .3s;
        z-index: 3;
        cursor: pointer;
        
        padding: 13px 17px;
        

    }
    
    .telegram_channel a {
        color: #fff;
        
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .telegram_channel a svg {
        fill: #fff;
    }
    
    .telegram_channel:hover {
        right: 0;
        
        background-color: #F5F7FF;
        color: #384ECB;
    }
    
    .telegram_channel:hover a {
        color: #384ECB;
    }
    
    .telegram_channel:hover a svg {
        fill: #384ECB;
    }
    
    .telegram_channel.glowing {
        animation: glowing 1300ms infinite;
        animation-delay: 10s;
        animation-iteration-count: 7;
    }
    
    .telegram_channel.glowing a {
        animation: glowing-a 1300ms infinite;
        animation-delay: 10s;
        animation-iteration-count: 7;
    }
    
    .telegram_channel.glowing a svg {
        animation: glowing-svg 1300ms infinite;
        animation-delay: 10s;
        animation-iteration-count: 7;
    }
    
    @keyframes glowing {
        0% { background-color: #384ECB; color: #F5F7FF;}
        50% { background-color: #F5F7FF; color: #384ECB;}
        100% { background-color: #384ECB; color: #F5F7FF;}
    }
    
    @keyframes glowing-a {
        0% { color: #F5F7FF;}
        50% { color: #384ECB;}
        100% { color: #F5F7FF;}
    }
    
    @keyframes glowing-svg {
        0% { fill: #F5F7FF;}
        50% { fill: #384ECB;}
        100% { fill: #F5F7FF;}
    }