#mission {
    padding: 0;
    overflow: hidden;
    position: relative;

    .mission-img {
        position: absolute;
        width: 100%;
        height: auto;

        .item-overlay {
            position: absolute;
            width: calc(50% + 12px);
            height: 100%;
            z-index: 2;
            background: rgba(14,26,94,0.35);
        }

        .item-img {
            width: calc(50% + 12px);

            img {
                width: 100%;
            }
        }
    }

    .mission-content {
        padding: 160px 0 160px 160px;

        p.title {
                font-weight: 700;
                font-size: 36px;
                line-height: 44px;
                color: #384ECB;
                margin-bottom: 63px;
            } 
    }
}