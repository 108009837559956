#tech_base {

    .blue-block {
        background-color: $blue;
        color: $white;
        padding: 50px;
        margin-bottom: 30px;
        height: 95%;

        p {
            margin-bottom: 20px;

            &.title {
                color: $white;
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
            }
        }

        ul {
            padding: 0;
            margin: 0;

            &.b-list {
                li {
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    margin-bottom: 20px;

                    &::before {
                        background-color: $blue;
                    }
                }
            }

            li {
                font-weight: 400;
                font-size: 18px;
                line-height: 33px;
                color: $white;
                position: relative;
                padding-left: 20px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 15px;
                    left: 5px;
                    height: 3px;
                    width: 3px;
                    border-radius: 50%;
                    background-color: $white;
                }
            }
        }
    }
}