#errorBlock {

    p.errorTitle {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        font-size: 338.051px;
        line-height: 412px;
        text-align: center;
        letter-spacing: 0.12em;
        color: $blue;
    }

    .description {
        padding-top: 100px;
        padding-left: 120px;

        p {
            font-size: 36px;
            line-height: 43px;
            margin-bottom: 120px;
        }

        a {
            position: relative;
            bottom: 50px;
            color: $white;
            padding: 20px 32px 20px 60px;
            background-color: $blue;
            border: solid 1px $blue;
            transition: $transition;

            &:hover {
                background-color: $white;
                color: $blue;
                transition: $transition;

                &::before {
                    background-color: $blue;
                    transition: $transition;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 31px;
                margin-top: -6px;
                margin-left: -9px;
                width: 18px;
                height: 12px;
                mask-image: url(/assets/template/images/icons/right_arrow.svg);
                transform: rotate(180deg);
                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
                background-color: $white;
                transition: $transition;
            }
        }
    }
}