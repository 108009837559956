#content {
    padding: 0;
    position: relative;

    ul {
        margin: 0;
        padding: 0;

        li {
            font-weight: 400;
            font-size: 18px;
            line-height: 33px;
            color: $grey;
            position: relative;
            padding-left: 20px;

            &::before {
                content: '';
                position: absolute;
                top: 15px;
                left: 5px;
                height: 3px;
                width: 3px;
                border-radius: 50%;
                background-color: $grey;
            }
        }
    }

    .block {
        padding: 160px 0px;

        &.pl120 {
            padding-left: 120px;
        }

        &.pr120 {
            padding-right: 120px;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                font-weight: 400;
                font-size: 18px;
                line-height: 33px;
                color: $grey;
                position: relative;
                padding-left: 20px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 15px;
                    left: 5px;
                    height: 3px;
                    width: 3px;
                    border-radius: 50%;
                    background-color: $grey;
                }
            }
        }

        &.image-block {
            padding: 0;
            margin: 0;
        }

        &.lightblue {
            background-color: $lightblue;
        }

        &.blue {
            background-color: $blue;
            
            p {
                color: $white;
            }

            ul {

                li {

                    color: $white;

                    &::before {
                        background-color: $white;
                    }
                }
            }
        }
    }

    .col-xxl-6 {
        &:nth-child(2n+1) {
                .block {
                    padding-right: 160px;
    
                    &.image-block {
                        padding: 0;
                    }
                }
        }
    
        &:nth-child(2n) {
                .block {
                    padding-left: 120px;
    
                    &.image-block {
                        padding: 0;
                        // width: 100%;
                        // height: 1px;
                        margin: 0;
                    }
                }
        } 
    }


    .col-xxl-6 {

        &.parent-image-block {
            padding: 0;
        }
    }

    .col-xxl-1,
    .col-xxl-2,
    .col-xxl-3,
    .col-xxl-4,
    .col-xxl-5,
    .col-xxl-7,
    .col-xxl-8,
    .col-xxl-9,
    .col-xxl-10,
    .col-xxl-11 {
        
        .block {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}