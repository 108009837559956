#service {
    padding-bottom: 0px;

    .row {
        &:nth-child(2) {
            margin-bottom: 30px;
        }

        @media screen and (min-width: 1200px) and (max-width: 1400px) {
            .col-xl-12 {
                .blue-block {
                    height: 350px;
                }
            }
        }
        
        .blue-block {
            position: relative;
            background-color: $blue;
            width: 100%;
            height: 612px;
            padding: 50px;
            margin-bottom: 30px;

            // @media screen and (min-width: 1400px) and (max-width: 1439px) {
            //     height: 750px;
            // }

            @media screen and (min-width: 1200px) and (max-width: 1399px) {
                height: 550px;
            }

            @media screen and (min-width: 480px) and (max-width: 1199px) {
                height: auto;
            }
    
            &.sb {
                position: relative;

                &::before {
                    position: absolute;
                    content: '';
                    background-image: url(/assets/template/images/tmp/service_bearing.png);
                    width: 250px;
                    height: 428px;
                    left:-250px;
                }
            }

            &:nth-child(2) {
                margin: 0 30px;
            }

            p {
                color: $white;
                font-weight: 400;
                font-size: 16px;
                line-height: 33px;
                margin-bottom: 20px;

                &.title {
                    font-size: 24px;
                    line-height: 29px;
                    text-transform: none;

                    
                }

                &:last-of-type {
                    @media screen and (min-width: 480px) and (max-width: 1200px) {
                        margin-bottom: 100px;
                    }
                }
            }

            a {
                position: absolute;
                bottom: 50px;
                color: $white;
                padding: 20px 60px 20px 32px;
                border: solid 1px $white;
                transition: $transition;

                // @media screen and (min-width: 1200px) and (max-width: 1439px) {
                //     padding: 20px 50px 20px 22px;
                // }

                &:hover {
                    background-color: $white;
                    color: $blue;
                    transition: $transition;

                    &:after {
                        background-color: $blue;
                        transition: $transition;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 31px;
                    margin-top: -6px;
                    margin-left: -9px;
                    width: 18px;
                    height: 12px;
                    mask-image: url(/assets/template/images/icons/right_arrow.svg);
                    mask-size: contain;
                    mask-position: center;
                    mask-repeat: no-repeat;
                    background-color: $white;
                    transition: $transition;
                }
            }
        }
    }
}