#main_news {
	padding: 0 0 160px;

	a {
		text-decoration: none;

		.main_block_news {
			background-color: $lightblue;
			padding: 35px;

			.mbn_date {
				margin-bottom: 20px;
				color: $blue;
			}

			.mbn_description {
				height: 160px;
				overflow: hidden;
				line-height: 24px;
				font-size: 14px;
				display: -webkit-box;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
			}
		}
	}
}

#news {

	.block_img {
		margin: 0 0 30px;
		background-color: $blue;

		.bimg {
			display: block;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			height: 325px;
			position: relative;
		}

		.bcontent {
			padding: 60px;

			p{
				color: white;

				&.btitle {
					font-style: normal;
					font-weight: bold;
					font-size: 18px;
					line-height: 27px;
				}

				&.bdescription {
					margin: 20px 0 40px;

					height: 116px;
					overflow: hidden;
					line-height: 24px;
					font-size: 14px;
					display: -webkit-box;
					-webkit-line-clamp: 5;
					-webkit-box-orient: vertical;
				}
			}

			.ebtn {
				width: 210px;
				height: 50px;
				color: $blue;
				font-family: Montserrat;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 17px;
				letter-spacing: 0.15em;
				text-align: center;
				text-transform: uppercase;
				border: none;

				background-color: $lightblue;
			}

		}
	}
}