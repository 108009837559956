// input {
//     margin: 20px 0;
//     min-height: 50px;
//     border: solid 1px #012a4c !important;
//     // background-color: #02050c !important;
//     color: #ccc !important;
// }


/* input */
  
input,
textarea {
    color: $blue;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $blue;
}

input:focus-visible {
    outline: none;
}

textarea {
    resize: none;
}

textarea::placeholder {
    color: #797979;
}

input#search {
    width: 100%;
}

input::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
}
input::-moz-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
}
input:-moz-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
}
input:-ms-input-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
}
input:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
}
input:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
}
input:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
}
input:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
}

textarea::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
}
textarea::-moz-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
}
textarea:-moz-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
}
textarea:-ms-input-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
}
textarea:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
}
textarea:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
}
textarea:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
}
textarea:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
}

/* end input */

/* input type[number] (arrows none) */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* end input type[number] (arrows none) */