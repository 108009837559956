/* montserrat-regular - latin_cyrillic */
@font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 400;
     src: url('/assets/template/fonts/montserrat-v23-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
   }
   /* montserrat-500 - latin_cyrillic */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 500;
     src: url('/assets/template/fonts/montserrat-v23-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-500.svg#Montserrat') format('svg'); /* Legacy iOS */
   }
   /* montserrat-700 - latin_cyrillic */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 700;
     src: url('/assets/template/fonts/montserrat-v23-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-700.svg#Montserrat') format('svg'); /* Legacy iOS */
   }
   /* montserrat-900 - latin_cyrillic */
   @font-face {
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 900;
     src: url('/assets/template/fonts/montserrat-v23-latin_cyrillic-900.eot'); /* IE9 Compat Modes */
     src: local(''),
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-900.woff') format('woff'), /* Modern Browsers */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */
          url('/assets/template/fonts/montserrat-v23-latin_cyrillic-900.svg#Montserrat') format('svg'); /* Legacy iOS */
   }