#partners {
    padding: 0;
    margin-bottom: 160px;

    .partners {
        width: calc(100% - 120px);
        margin: 0 auto;
        min-width: 0;
        position: relative;
    
        .partner_item {
            margin: 0 20px;
            // height: 60px;
            
            img {
                width: 100%;
            }
        }

        .slick-list {
            overflow: hidden;
    
            .slick-track {
                display: flex;
                align-items: flex-start;
            }
        }
    
        .slick-arrow {
            position: absolute;
            top: 20px;
            // bottom: 103px;
            z-index: 2;
            font-size: 0;
            border: 0;
            width: 60px;
            height: 60px;
            background: $blue;
            transition: all .3s;
    
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -8px;
                margin-left: -12px;
                width: 24px;
                height: 17px;
                mask-image: url(/assets/template/images/icons/right_arrow.svg);
                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
                background-color: $white;
            }

            &:hover {
                transition: all .3s;
                background-color: $lightblue;

                &::after {
                    background-color: $blue;

                }
            }

            &.slick-prev {
                left: -60px;

                &::after {
                    transform: rotate(180deg);
                }

            }
    
            &.slick-next {
                right: 0;
                right: -60px;
    
                &::after {}
            } 
        }
    
        .slick-dots {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: 100px;
    
            li {
                list-style: none;
    
                &.slick-active {
                    button {
                        background-color: $blue;
                        cursor: auto;
                    }
                }
    
                button {
                    font-size: 0;
                    width: 10px;
                    height: 10px;
                    background-color: $lightblue;
                    border: none;
                    margin-right: 10px;
                    padding: 0;
                    cursor: pointer;
                }
            }
        }
    
        .slider__item {
            position: relative;
    
            .slider-content {
                padding: 120px 0;
        
                p {
                    &.title {
                        text-transform: uppercase;
                    }
                }
            }
    
            .slider-image {
                height: 100%;
                position: absolute;
                overflow: hidden;
                top: 0;
                right: 0;
                width: calc(50% - 12px);
                z-index: -1;
                
                .img-overlay {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    background: rgba(14, 26, 94, 0.35);
        
                    &::after {
                        content: '';
                        position: absolute;
                        right: -100%;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        background: rgba(14, 26, 94, 0.35);
                    }
                }
        
                .bg-image {
                    height: 100%;
                    width: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}