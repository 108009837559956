body::-webkit-scrollbar {             
    width: 6px;             
}

body::-webkit-scrollbar-thumb {
    background-color: $blue;
    border-left: 1px solid $white;
    border-right: 1px solid $white;
}

body::-webkit-scrollbar-track {
    background-color: $white;
}