.back_width_title {
	display: flex;
	justify-content: space-between;
	
	.back_btn {
		padding: 16px;
		height: 50px;
		background-color: $blue;
		color: white;

		&:hover {
			background-color: $lightblue;
			color: $blue;
		}
	}
}