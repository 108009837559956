#auth {
    padding-top: 0;
    padding-bottom: 0;

    .auth {
        width: 100%;
        height: calc(100vh - 72px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -75px;
    
        @media screen and (min-width: 1200px) {
            justify-content: left;
        }

        h1 {
            margin-bottom: 80px;
            color: $blue;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 44px;
            text-transform: uppercase;
        }

        .auth-form {
            display: flex;
            flex-direction: column;
            width: 360px;

            input {
                border: none;
                background-color: $lightblue;
                margin-bottom: 20px;
                padding: 17px 30px;

                &::placeholder {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 26px;
                    letter-spacing: -0.015em;
                    color: $grey;
                }
            }

            button {
                background-color: $blue;
                border: solid 1px $blue;
                min-width: 120px;
                color: $white;
                transition: $transition;
                position: relative;
                max-width: 180px;
                padding: 20px 60px 20px 32px;
                
                &:hover {
                    background-color: $white;
                    color: $blue;
                    transition: $transition;

                    &:after {
                        background-color: $blue;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 50px;
                    margin-top: -6px;
                    width: 18px;
                    height: 12px;
                    mask-image: url(/assets/template/images/icons/right_arrow.svg);
                    mask-size: contain;
                    mask-position: center;
                    mask-repeat: no-repeat;
                    background-color: $white;
                    transition: $transition;
                }
            }
        }
    }

    .registration {
        width: 100%;
        height: calc(100vh - 72px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -75px;

        border-left: 1px solid $blue;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 25%;
            background-color: white;
            top: 0;
            left: -1px;
            z-index: 1;
        }

        &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 25%;
            background-color: white;
            bottom: 0;
            left: -1px;
            z-index: 1;
        }

        .callback-form {
            display: flex;
            flex-direction: column;
            width: 360px;

            input {
                border: none;
                background-color: $lightblue;
                margin-bottom: 20px;
                padding: 17px 30px;

                &::placeholder {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 26px;
                    letter-spacing: -0.015em;
                    color: $grey;
                }

                &[type=submit] {
                    background-color: $blue;
                    border: solid 1px $blue;
                    min-width: 160px;
                    color: $white;
                    transition: $transition;
                    position: relative;
                    max-width: 240px;
                    padding: 20px 60px 20px 32px;
                    
                    &:hover {
                        background-color: $white;
                        color: $blue;
                        transition: $transition;
    
                        &:after {
                            background-color: $blue;
                        }
                    }
    
                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 50px;
                        margin-top: -6px;
                        width: 18px;
                        height: 12px;
                        mask-image: url(/assets/template/images/icons/right_arrow.svg);
                        mask-size: contain;
                        mask-position: center;
                        mask-repeat: no-repeat;
                        background-color: $white;
                        transition: $transition;
                    }
                }
            }
        }
    }
}